import usdc from '../../asset/public/usdc.png'
import pina from '../../asset/public/plogo.png'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { useContractRead } from 'wagmi';
import { getGenesisSaleAddress, getGenesisSaleAbi } from '../../config/contracts'
import { useEffect } from 'react';
import { ethers } from 'ethers';
import Box from '@mui/material/Box'

export default function OverAllInfo(props: any) {

    const [{ data, error, loading }, read] = useContractRead(
        {
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        },
        'totalInvestors',
    )

    const [{ data: totalData, error: totalError, loading: totalLoading }, totalRead] = useContractRead(
        {
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        },
        'totalGlobalInvested',
    )

    useEffect(() => {
        read()
        totalRead()
    }, []);

    useEffect(() => {
        read()
        totalRead()
    }, [props.update]);

    const contentStyle = {
        color: 'white',
        margin: 'auto',
        padding: '25px',
        marginBottom: '10px',
        borderRadius: '10px'
    }

    return (<Box sx={{ width: { xl: '100%', lg: '100%', md: '625px', sm: '625px', xs: '100%' }, margin:'auto'}}>
        <div className='nav-title'>Genesis Sale Overall</div>
        <div className="glassBackground" style={contentStyle}>
            <div style={{ fontSize: "14px", color: 'rgb(173, 175, 180)' }}>Total Raised</div>
            <div style={{ fontSize: "20px" }}>
                {totalData && formatFromBaseUnit(totalData, 18).toFixed(2)}
                <img src={usdc} style={{ width: '12px', marginBottom: '-2px', borderRadius: '50%', marginLeft: '10px' }} />
                <a style={{ fontSize: '14px', }}> WETH </a>
                {/* <a style={{ color: 'rgb(14, 200, 113)', fontWeight: '600', marginLeft: '10px' }}>{totalData && percentage(formatFromBaseUnit(totalData, 18), 6000000)}</a> */}
            </div>
            <div style={{ height: '12px', width: '100%' }} />
            <div style={{ fontSize: "14px", color: 'rgb(173, 175, 180)' }}>Max Supply for sale</div>
            <div style={{ fontSize: "20px" }}>
                {250000}
                <img src={pina} style={{ width: '12px', marginBottom: '-1px', marginLeft: '10px' }} />
                <a style={{ fontSize: '14px' }}> PINA </a>
            </div>

            <div style={{ height: '12px', width: '100%' }} />
            <div style={{ fontSize: "14px", color: 'rgb(173, 175, 180)' }}>Total Participants</div>
            <div style={{ fontSize: "20px" }}>
                <AccessibilityNewIcon style={{ fontSize: "20px", marginBottom: '-3px', marginRight: '3px', color: 'rgb(239, 131, 39)' }} />
                {data && formatToNumber(data)}
            </div>
        </div>
    </Box>)
}

const formatToNumber = (n: any) => ethers.BigNumber.from(n).toNumber()
 const formatFromBaseUnit = (amount: any, decimals: any) => Number(ethers.utils.formatUnits(ethers.BigNumber.from(amount), decimals))
