import Hermes2pina from './hermes2pina'
import PinaPrice from './pinaPrice'
import MetisNetwork from './metisNetwork'
import ConnectWallet from './connectWallet'
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import Chevron from '../../asset/public/chevron-up.svg'
import { useState } from 'react';

export default function AppNavbar(props: any) {

    const regularScreen = useMediaQuery('(min-width:900px)');
    const smartPhone = !useMediaQuery('(min-width:700px)');

    const [open, setOpen] = useState(false)

    const navbarStyle = {
        height: regularScreen || !open && smartPhone ? '71px' : (smartPhone ? '270px' : '110px'),
        width: '100%',
        borderBottom: '1px solid rgb(30, 39, 76)',
        background: '#1d124a',
        display: 'relative',
        zIndex: '99'
    }

    return (
        <div style={navbarStyle}>
            <div style={{ padding: '21px 21px 21px 0px', position: 'relative', width: 'calc(100% - 42px)' }}>
                <a href="../"><div className="nav" style={{ width: 'fit-content', display: 'inline-block', marginLeft: '50px', }}>PINACOTHECA </div></a>
                {regularScreen ? <a /> : <br />}
                {!smartPhone && (<>
                    <a href="../public-sale"><div className="nav" style={{ width: 'fit-content', display: 'inline-block', marginLeft: '50px', }}>Public Sale </div></a>
                    <div style={{ position: "absolute", right: '0', marginTop: regularScreen ? '-45px' : '15px', height: 'fit-content', }}>
                        <MetisNetwork />
                        <PinaPrice />
                        <ConnectWallet />
                    </div></>)}

                {smartPhone && (
                    <div>
                        <a className="chevron-btn">
                            <img src={Chevron} style={{ width: '20px', position: 'absolute', right: '10px', top: '25px' }} onClick={() => { setOpen(!open) }} />
                        </a>
                        {open ?
                            <div className='nav-expand' style={{ marginLeft: '20px' }}>
                                <a href="../public-sale"><div className="nav" style={{ width: 'fit-content', marginLeft: '30px', marginTop:'10px'}}>Public Sale </div></a>
                                <a href="../genesis-sale"><div className="nav" style={{ width: 'fit-content', marginLeft: '30px', marginTop:'10px' }}>Genesis Sale </div></a>
                                <Grid container>
                                    <Grid item xs={12}><Hermes2pina smartPhone /></Grid>
                                    <Grid item xs={12}><MetisNetwork smartPhone /></Grid>
                                    <Grid item xs={12}><PinaPrice smartPhone /><ConnectWallet smartPhone /></Grid>
                                </Grid>
                            </div> : <div className='nav-not-expand' />}
                    </div>)}
            </div>
        </div>)
}