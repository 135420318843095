import Metamask from '../../asset/public/metamask.svg';
import { useAccount, useConnect } from 'wagmi'
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ConnectWallet(props: any) {
    const [{ data, error }, connect] = useConnect()
    const [{ data: accountData, error: accountError, loading }, disconnect] = useAccount({ fetchEns: true, })
    const [hover, onHover] = useState(false)
    const smartPhone = !useMediaQuery('(min-width:600px)');

    const getBtnFill = (accountData: any, loading: boolean) => {
        if (!accountData) { return 'Connect' }
        if (accountData && loading) { return 'Loading Wallet...' }
        if (accountData && !loading) { return sliceAddress(accountData.address) }
    }

    const style = {
        height: '20px',
        padding: '10px',
        display: 'inline-block',
        marginLeft: '10px',
        position: 'relative',
        top: smartPhone?'-23px':'-9px',
        cursor: 'pointer',
        borderRadius: '7px',
        marginTop: props.smartPhone ? '-2px' : '0px',
        width: 'auto',
    } as const
    const iconStyle = {
        height: '20px',
        display: 'inline-block',
    } as const
    const connectBtnStyle = {
        display: 'inline-block',
        position: 'relative',
        top: '-4px',
        color: 'white',
        marginLeft: '7px',
    } as const

    return (<div className="flowing-background" style={style}>
        <img src={Metamask} style={iconStyle} />
        {(data && data.connected) ?
            <div style={connectBtnStyle} onClick={disconnect} onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)}>
                {hover ? "Disconnect" : getBtnFill(accountData, loading)}
            </div>
            : <div style={connectBtnStyle} onClick={() => connect(data.connectors[0])}>{getBtnFill(accountData, loading)}</div>}
    </div>)
}

function sliceAddress(address: any) {
    if (address) {
        return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    } else {
        return ''
    }
}