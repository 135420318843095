import './tv.css'

export default function TV(props: any) {

    function power() {
        let body = document.getElementById("wrapper");
        if (body) { body.className = (body.className == "on") ? "off" : "on"; }
    }

    function reclick(){
        let body = document.getElementById("wrapper");
        if (body) { body.className == "on"? props.onClick() : power()}
    }

    return (
        <div id="wrapper" className="on" onClick={reclick} style={{cursor:'pointer'}}>
        <div id="screen">
            <div id="content-holder">
                <div id="content">
                    <div id="app">
                        <div id="channel">AV1</div>
                        <div id="text">Click here to enter pinacothena.</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="power" onClick={power}></div>
    </div>)
}