import { useToken } from 'wagmi'
import { contract } from '../../config/contracts'

export default function PinaPrice(props: any) {
    const [{ data, error, loading }, getToken] = useToken({
        address: contract[4].HERMES,
    })
    const style = {
        height: '20px',
        padding: '10px',
        display: 'inline-block',
        marginLeft: '10px',
        // width: '110px',
        position: 'relative',
        top: props.smartPhone?'5px':'20px',
    } as const
    const logoStyle = {
        background: '-webkit-linear-gradient(-45deg, #f700c0, #fb7)',
        width: '40px',
        height: "40px",
        borderRadius: '10px',
        fontSize: '30px',
        fontWeight: '900',
        textShadow: '3px 3px red',
    }
    return (<div className="glassBackground glassBackgroundbtn" style={style}>
        <div style={{ margin: '-10px 0px 0px -10px', display: 'inline-blocks' }}><div style={logoStyle}><a className="changing-logo" style={{ marginLeft: '7px', top: '-3px', position: 'relative' }}>P</a></div></div>
        <div style={{ display: 'inline-block', position: 'relative', top: '-31px', color: 'white', marginLeft: '40px', fontWeight: '600' }}><a style={{ fontWeight: '500' }}>$</a>
            0.40
        </div>
    </div>)
}