import faci from '../../asset/home/faci.png'
// import { ReactComponent as Arrow } from '../../asset/home/arrowDown.svg'
import { ReactComponent as Arrow } from '../../asset/home/down-arrow.svg'
export default function Intro(props: any) {

    const shadowColor = "118,2,137"
    const gifStyle = {
        boxShadow: "rgba(" + shadowColor + ", 0.9) -5px 5px," +
            "rgba(" + shadowColor + ", 0.8) -15px 15px, " +
            "rgba(" + shadowColor + ", 0.7) -25px 25px, " +
            "rgba(" + shadowColor + ", 0.6) -35px 35px, " +
            "rgba(" + shadowColor + ", 0.5) -45px 45px," +
            "rgba(" + shadowColor + ", 0.4) -55px 55px," +
            "rgba(" + shadowColor + ", 0.3) -65px 65px," +
            "rgba(" + shadowColor + ", 0.2) -75px 75px," +
            "rgba(" + shadowColor + ", 0.1) -85px 85px," +
            "rgba(" + shadowColor + ", 0.05) -95px 95px"
    }
    return (
        <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <img className="faci" src={faci} style={{ height: "100vh", transform: 'scaleX(-1)', position: 'absolute', left: '0', top: '0', marginLeft: '-10%', opacity: '0.8' }} />
            <img className="faci" src={faci} style={{ height: "100vh", position: 'absolute', right: '0', top: '0', marginRight: '-10%', opacity: '0.8' }} />
            <div style={{ position: 'relative' }}>
                <Arrow className="arrow" fill="white" style={{ width: '50px', display: 'block', }} />
                <Arrow className="arrow" fill="white" style={{ width: '50px', display: 'block', right: '0' }} />
                {/* <a href='/public-sale'> */}
                    <div className='intro'>Public Sale Coming Soon</div>
                {/* </a> */}
            </div>
        </div>)
}