import metis from '../../asset/public/metis.jpg'
import evmos from '../../asset/evmos.png'
import { useNetwork, useConnect } from 'wagmi'
import { devChainId } from '../../config/globalConfig'
import { useState } from 'react';

export default function MetisNetwork(props: any) {
    const [{ data, error, loading }, switchNetwork] = useNetwork()
    const [hover, onHover] = useState(false)

    const style = {
        height: '20px',
        padding: '10px',
        display: 'inline-block',
        marginLeft: '10px',
        marginTop: props.smartPhone ? '20px' : '0px'
    }

    const iconStyle = {
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        background: 'black',
        display: 'inline-block',
        margin: '-10px 0px 0px -10px',
    }

    return (<div className="glassBackground glassBackgroundbtn" style={style} onClick={async () => switchNetwork ? await switchNetwork(devChainId) : {}}>
        <img src={evmos} style={iconStyle} />
        <div style={{ display: 'inline-block', position: 'relative', top: '-12px', color: 'white', marginLeft: '10px' }}>
            {data.chain ? (data.chain.id == devChainId ? 'EVMOS Network' :
                <a style={{ color: 'red', fontWeight: '600' }} onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)}>Switch to EVMOS</a>
            ) : 'EVMOS Network'}
        </div>
    </div>)
}