
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import store from "./store"
import Web3Container from './pages/web3-container';
import Home from './pages/home';
import PublicSale from './pages/publicSale';
import GenesisSale from './pages/genesisSale';
import { Provider, useNetwork } from 'wagmi'
import { Metis, Rinkeby } from './config/chain';
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { WalletLinkConnector } from 'wagmi/connectors/walletLink'
import { providers } from 'ethers';
import { Provider as ReduxProvider } from "react-redux"
import { devChainId } from './config/globalConfig';

// API key for Ethereum node
// Two popular services are Infura (infura.io) and Alchemy (alchemy.com)
const infuraId = process.env.INFURA_ID

// Chains for connectors to support
// Set up connectors

const chains = [Metis, Rinkeby]
const connectors = ({ chainId }: any) => {
	let rpcUrl: string[] = []
	if (chainId === Metis.id) {
		rpcUrl = Metis.rpcUrls
	}
	if (chainId === Rinkeby.id) {
		rpcUrl = Rinkeby.rpcUrls
	}
	return [
		new InjectedConnector({
			chains,
			options: { shimDisconnect: true },
		}),
		new WalletConnectConnector({
			options: {
				infuraId,
				qrcode: true,
			},
		}),
		new WalletLinkConnector({
			options: {
				appName: 'My wagmi app',
				jsonRpcUrl: `${rpcUrl}`,
			},
		}),
	]
}

const provider = ({ chainId }: any) => {
	let rpcUrl: string[] = Metis.rpcUrls
	if (chainId === Metis.id) {
		rpcUrl = Metis.rpcUrls
	}
	if (chainId === Rinkeby.id) {
		rpcUrl = Rinkeby.rpcUrls
	}
	return new providers.JsonRpcProvider(rpcUrl[0])

}

export default function App() {
	return (
		<div>
			<ReduxProvider store={store}>
				<Provider autoConnect connectors={connectors} provider={()=>provider(devChainId)}>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/public-sale" element={<GenesisSale />} />
						</Routes>
					</BrowserRouter>
				</Provider>
			</ReduxProvider>

		</div>
	);
}

