import React, { useState } from 'react';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ImportExportIcon from '@mui/icons-material/ImportExport';

export default function Hermes2pina(props: any) {
    
    const [hermes2pina, setHermes2pina] = useState(true)
    function GetRatio(ratio: number) {
        if (hermes2pina) return (<a className='left'><a style={{ fontWeight: '600' }}>1</a> HERMES <ImportExportIcon className="rotation-btn" style={rotationStyle} /> <a style={{ fontWeight: '600' }}>{ Math.round(1 / ratio * 10000) / 10000 }</a> PINA</a>)
        else return (<a className='right'><a style={{ fontWeight: '600' }}>1</a> PINA <ImportExportIcon className="rotation-btn" style={rotationStyle} /> <a style={{ fontWeight: '600' }}> {ratio}</a> HERMES</a>)
    }
    const rotationStyle = {
        marginBottom: '-4px',
        fontSize: '18px'
    }
    const style = {
        height: '20px',
        padding: '10px',
        display: 'inline-block',
        position: 'relative',
        top: props.smartPhone? '12px':'-12px',
        marginLeft: '10px',
    } as const
    const exchangeStyle = {
        color: '#fb7',
        fontSize: '14px',
        marginBottom: '-2px'
    }
    return (<div className="glassBackground glassBackgroundbtn" style={style} onClick={() => setHermes2pina(!hermes2pina)}>
        <div style={{ display: 'inline-block', position: 'relative', top: '-0px', marginLeft: '5px', color: 'white', marginRight: '7px' }}>{GetRatio(8)}</div>
        <CurrencyExchangeIcon className="exchange-btn" style={exchangeStyle} />
    </div>)
}