import daddy from '../../asset/genesis/sculpture.png'
import usdc from '../../asset/public/usdc.png'
import Box from '@mui/material/Box'
import { useAccount, useBalance, useContractRead, useContractWrite, useWaitForTransaction } from 'wagmi'
import { getGenesisSaleAddress, getGenesisSaleAbi, getUsdcToken, getErc20Abi } from '../../config/contracts'
import { ethers, utils } from 'ethers'
import { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import PinaSnackbar from '../../component/pinaSnackbar';



export default function PercentageChart(props: any) {

    const smartPhone = !useMediaQuery('(min-width:600px)');
    const small = !useMediaQuery('(min-width:1200px)');
    const [amount, setAmount] = useState('')
    const [txnLoading, setTxnLoading] = useState(false)
    const [snackBarOpen, setSnackBarOpen] = useState(0)

    //approve
    const [{ data: approveWriteData, error: approveWriteError, loading: approveWriteLoading }, approveWrite] =
        useContractWrite({
            addressOrName: getUsdcToken(props.chainId),
            contractInterface: getErc20Abi(),
        }, 'approve')

    if (approveWriteError && txnLoading) {
        setSnackBarOpen(-1)
        setTxnLoading(false)
    }

    if (approveWriteData && txnLoading) {
        approveWriteData.wait().then(() => {
            setSnackBarOpen(1)
            setTxnLoading(false)
            approveRead()
        })
    }

    //deposit
    const [{ data, error, loading }, write] =
        useContractWrite({
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        }, 'invest')

    if (data && txnLoading) {
        data.wait().then(() => {
            setTxnLoading(false)
            props.updateFn(!props.update)
            totalRead()
            getBalance()
            setAmount('')
            setSnackBarOpen(1)
        })
    }
    if (error && txnLoading === true) {
        setTxnLoading(false)
        setSnackBarOpen(-1)
    }

    const [{ data: totalData, error: totalError, loading: totalLoading }, totalRead] = useContractRead({
        addressOrName: getGenesisSaleAddress(props.chainId),
        contractInterface: getGenesisSaleAbi(),
    },
        'totalGlobalInvested',
    )

    const [{ data: approveData, error: approveError, loading: approveLoading }, approveRead] = useContractRead({
        addressOrName: getUsdcToken(props.chainId),
        contractInterface: getErc20Abi(),
    },
        'allowance', {
        args: [props.address, getGenesisSaleAddress(props.chainId)]
    })

    const [{ data: saleEnabledData, error: saleEnabledError, loading: saleEnabledLoading }, saleEnabledRead] = useContractRead(
        {
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        },
        'saleEnabled'
    )


    let hermesLeft = 0
    let percent
    let pinaLeft
    if (totalData) {
        hermesLeft = 100000 - (Number(formatFromBaseUnit(totalData, 18)))
        percent = percentage(formatFromBaseUnit(totalData, 18), 100000)
    }
    const [{ data: balanceData, error: balanceError, loading: balanceLoading }, getBalance] = useBalance({
        addressOrName: props.address,
        token: getUsdcToken(props.chainId),
    })
    const wrap = async () => {
        setTxnLoading(true)
        await write({ args: [formatToBaseUnit(amount, 6)] })
    }
    const approveTransaction = async () => {
        setTxnLoading(true)
        await approveWrite({ args: [getGenesisSaleAddress(props.chainId), maxApprovalAmount] })
    }
    const getBtn = () => {
        if (approveData == undefined || saleEnabledData == undefined) {
            return <a style={{ position: 'relative', fontWeight: '500', top: '-3px', fontSize: '14px' }}>Loading</a>
        } else {
            const allowance = formatFromBaseUnit(approveData, 18)
            if (!saleEnabledData) {
                return <a style={{ position: 'relative', fontWeight: '500', top: '-3px', fontSize: '14px', cursor: 'not-allowed' }}>Sale Disabled</a>
            } else if (allowance === 0) {
                return <a style={{ position: 'relative', fontWeight: '500', top: '-3px' }} onClick={async () => await approveTransaction()}>Approve</a>
            } else {
                return <a style={{ position: 'relative', fontWeight: '500', top: '-3px' }} onClick={async () => await wrap()}>Deposit</a>
            }
        }
    }
    const handleChange = (e: any) => { setAmount(e.target.value) }

    useEffect(() => {
        if (props.address) {
            approveRead()
            getBalance()
            saleEnabledRead()
        }
    }, [props.address])

    const style = {
        height: '20px',
        padding: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '7px',
        display: 'inline-block',
        width: smartPhone ? "90%" : '100px',
        marginLeft: smartPhone ? '3px' : '10px',
        position: 'relative',
        top: smartPhone ? '10px' : '0px',
    } as const
    const contentStyle = {
        color: 'white',
        margin: 'auto',
        padding: { xl: '50px', lg: '50px', md: '50px', sm: '50px', xs: '5vw' },
        marginBottom: '20px',
        borderRadius: '10px',
        height: '645px',
        width: { xl: 'auto', lg: 'auto', md: '525px', sm: '525px', xs: '80vw' }
    }
    const iconStyle = {
        display: 'block',
        height: (Number(percent) * 0.9).toString() + '%',
        width: smartPhone ? '100vw' : '600px',
        margin: 'auto',
        objectFit: "cover",
        objectPosition: 'bottom',
        position: 'absolute',
        bottom: '0',
        marginLeft: small ? '-50px' : '-100px',

    } as const
    const icon2Style = {
        height: '100%',
        margin: 'auto',
        opacity: "0.3",
        width: smartPhone ? '100vw' : '600px',
        display: "block",
        marginLeft: small ? '-50px' : '-100px',
    }
    const percentageStyle = {
        color: '#f700c0',
        position: 'absolute',
        top: smartPhone ? '130px' : '150px',
        left: smartPhone ? '100px' : (small ? "200px" : '150px'),
        textAlign: 'center',
        fontSize: smartPhone ? '30px' : '40px',
        fontWeight: '600',
        zIndex: '999',
    } as const
    const logoStyle = {
        background: '-webkit-linear-gradient(-45deg, #f700c0, #fb7)',
        width: '50px',
        height: "50px",
        borderRadius: '50px',
        fontSize: '35px',
        fontWeight: '900',
        textShadow: '3px 3px red',
        marginLeft: '43px',
        top: '-54px',
        position: 'relative',
    } as const
    const inputStyle = {
        background: 'rgba(226,204,255,0.2)',
        width: smartPhone ? '90%' : '262px',
        display: 'inline-block',
        borderRadius: '7px',
        height: '18px',
        padding: '10px',
        marginTop: '10px',
        fontFamily: 'acumin-pro-wide',
        color: 'white',
        fontSize: '20px',
        boredr: 'transperant'
    }
    const btnStyle = {
        fontSize: '12px',
        position: 'absolute',
        left: smartPhone ? "80%" : '290px',
        marginTop: '-31px',
    } as const
    function isValid(value: string | number): boolean {
        return ((value === '.') ||
            ((value != null) &&
                (value !== '') &&
                (value !== '-') &&
                (value !== 'e') &&
                !isNaN(Number(value.toString()))));
    }
    return (
        <Box className="glassBackground" id="percentagebox" sx={contentStyle}>
            <div style={{ display: 'grid', gridTemplateColumns: '110px auto', width: '100%' }}>
                <div>
                    <img src={usdc} style={{ width: '50px', height: '50px', borderRadius: '50px' }} />
                    <div style={logoStyle}>
                        <a className="changing-logo" style={{ color: 'white', marginLeft: '11px', top: '-4px', position: 'relative' }}>P</a>
                    </div>
                </div>
                <div>
                    <div style={{ fontSize: '14px', marginTop: '0px' }}>Pina Genesis Sale Price: </div>
                    <div style={{ marginLeft: '0px', fontSize: '20px', fontWeight: "600", }}>0.40 WETH
                        {/* /<a style={{ fontSize: "14px" }}> -.--- USDC<img src={usdc} style={{ marginLeft: '5px', width: '16px', height: '16px', borderRadius: '50px', position: 'relative', bottom: '-2px' }} /></a>  */}
                    </div>
                </div>
            </div>
            <div className="genesis-sale-percentage" style={{ position: 'relative', top: '-20px' }}>
                <div className='nav-title' style={{ width: '100%', textAlign: 'center', fontSize: '16px !important', position: 'relative' }}>{hermesLeft.toFixed(2)} <a style={{ fontSize: '13px' }}>WETH Left</a></div>
                <img src={daddy} style={iconStyle} />
                <img className="genesis-sale-percentage-chart" src={daddy} style={icon2Style} />
                <div className="genesis-sale-percentage-amount-left" style={percentageStyle}>{(100 - Number(percent)).toFixed(2)}% <br /><a style={{ fontSize: smartPhone ? '25px' : '35px', top: '-20px', position: 'relative', color: '#b60fc1', fontWeight: '800' }}>LEFT</a></div>
            </div>
            <div className="nav-title" style={{ textTransform: 'uppercase', marginTop: smartPhone ? '20px' : '0px' }}>Deposit your USDC here:</div>
            <input type="text"
                style={inputStyle}
                value={amount}
                onChange={e => handleChange(e)}
                onKeyPress={(e: any) => { if (!isValid(e.key)) { e.preventDefault(); } }}
            />
            {approveData && <Box sx={btnStyle} className='nav-title' onClick={() => balanceData && setAmount(formatFromBaseUnit(balanceData.value, 6).toString())}>MAX</Box>}
            <div className="flowing-background" style={style} >
                {props.address ? getBtn() : <a style={{ fontSize: '12px', position: 'relative', top: '-4px', fontWeight: '600', cursor: 'not-allowed' }}>Connect Wallet</a>}
            </div>
            {txnLoading && <PinaSnackbar open={true} message='Transaction Loading...' type="info" />}
            {snackBarOpen == 1 && <PinaSnackbar open={true} message='Transaction Success!' type="success" />}
            {snackBarOpen == -1 && <PinaSnackbar open={true} message='Transaction Failed!' type="error" />}
        </Box>)

}

const formatToBaseUnit = (amount: any, decimals: any) => ethers.BigNumber.from(ethers.utils.parseUnits(amount.toString(), decimals))
function percentage(partialValue: number, totalValue: number) {
    return ((100 * partialValue) / totalValue).toFixed(2);
}
const formatFromBaseUnit = (amount: any, decimals: any) => Number(ethers.utils.formatUnits(ethers.BigNumber.from(amount), decimals))
const maxApprovalAmount = ethers.BigNumber.from(10).pow(28)
