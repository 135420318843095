import Navbar from '../../widgets/navbar/styledNavbar'


export default function Title(props: any) {

    return (
        <div style={{ width: '90%', margin: 'auto', marginTop:'80px', height: '100%' }} onClick={props.onClick}>
            <Navbar />
            <div style={{height:'100%', width:'100%', background:'red'}}/>
        </div>
    )
}