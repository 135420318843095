import stars from '../asset/public/stars.svg'
import Navbar from '../widgets/navbar'
import Grid from '@mui/material/Grid'

import "./genesisSale/style.css"
import PercentageChart from './genesisSale/percentageChart'
import TimeInfo from './genesisSale/TimeInfo'
import OverAllInfo from './genesisSale/overAllInfo'
import PersonalInfo from './genesisSale/personalInfo'
import MovingBanner from '../component/movingBanner2/movingBanner'
import { useAccount, useConnect, useNetwork } from 'wagmi'
import { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { devChainId } from '../config/globalConfig'
import Snackbar from '@mui/material/Snackbar';

export default function GenesisSale(props: any) {
    const regularScreen = useMediaQuery('(min-width:920px)');
    const [{ data, error, loading }, disconnect] = useAccount()
    const [chainId, setChainId] = useState(0)
    const [update, setUpdate] = useState(false)

    if (data && data.connector) {
        data?.connector?.getChainId().then(x => setChainId(x))
    }
    if (chainId !== 0 && chainId !== devChainId) {
        return (<div style={{ width: '100%', overflowX: 'hidden', height: '99vh' }}>
            <Navbar />
            <div className='subTitle' style={{ display: 'block', fontWeight: '900', width: '100%', marginTop: '10%', textAlign: 'center', cursor: 'default' }}>Wrong Network</div>
            <div className='nav-title' style={{ display: 'block', width: '100%', marginTop: '50px', textAlign: 'center', color: 'white', textTransform: 'capitalize' }}> Please click "Switch to EVMOS" to add EVMOS in your Metamask</div>
        </div>)
    }
    else {
        return (
            <div style={{ width: '100%', overflowX: 'hidden' }}>
                <Navbar />
                <div>
                    <img src={stars} style={{ width: '100%', position: 'absolute', zIndex: '-1', height: 'calc(99vh - 65px)', objectFit: "cover" }} />
                    <MovingBanner />
                    <div style={{ width: regularScreen ? '900px' : '100%', margin: 'auto', }}>
                        <Grid container spacing="20px">
                            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                                <PersonalInfo address={data?.address} chainId={devChainId} update={update} />
                                <OverAllInfo address={data?.address} chainId={devChainId} update={update} />
                            </Grid>
                            <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                                <PercentageChart address={data?.address} chainId={devChainId} update={update} updateFn={setUpdate} />
                            </Grid>
                        </Grid>
                    </div>
                    <MovingBanner />
                </div>
            </div>)
    }
}


