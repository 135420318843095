import { publicSaleAbi } from "./ABI/publicSaleAbi"
import { useContractWrite } from 'wagmi'
import { ERC20_ABI } from "./ABI/erc20Abi"
import { genesisSaleAbi } from "./ABI/genesisSaleAbi"
export const contract = {
    4: {
        HERMES: '0xc353Af0AFe942a41A836171eE092aBfeF6125F93',
        PUBLICSALE: '0xcCA9A71F28f9A958435bE0fA93231Aa2Cd74B4a7',
        PINA: '0x7826B2495710fF25883d11D7BD1892aacc1DE012',
        PUBLICSALE_ABI: publicSaleAbi,
        USDC: '0xDA1Ed4eC90863E1d1304F938499dF353a1789f54',
        GENESISSALE: '0xe681d78fdB5274831510aE1185999D093b72bCC9'
    },
    1088: {
        HERMES: '0xb27BbeaACA2C00d6258C3118BAB6b5B6975161c8',
        PUBLICSALE: '0xedBec542919D031770DCC4065199F893c6095786',
        PINA: '0xD41d65E038C0F936FD4A34D73F3Dd71db4d6423a',
        PUBLICSALE_ABI: publicSaleAbi,
        USDC: '0xEA32A96608495e54156Ae48931A7c20f0dcc1a21',
        GENESISSALE: '0xdF9fEd40b4dA1566E30fAD2B93a5542D2269C4B4'
    }
}

export const getErc20Abi = () => {
    return ERC20_ABI
}

export const getHermesToken = (chainId) => {
    return contract[chainId].HERMES
}

export const getPublicSaleAddress = (chainId) => {
    return contract[chainId].PUBLICSALE
}

export const getPublicSaleAbi = () => {
    return publicSaleAbi
}

export const getUsdcToken = (chainId) => {
    return contract[chainId].USDC
}

export const getGenesisSaleAddress = (chainId) => {
    return contract[chainId].GENESISSALE
}

export const getGenesisSaleAbi = () => {
    return genesisSaleAbi
}

