import { useState } from 'react'
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import * as React from 'react';

export default function PinaSnackBar(props) {
    // error | warning | info | success

    const [open, setOpen] = useState(props.open);
    const handleClose = () => { setOpen(false); };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const elm = document.querySelector('#percentagebox');
    const left = elm.offsetLeft
    return (<Snackbar open={open} onClose={handleClose} autoHideDuration={6000} sx={{ position: 'absolute', marginLeft: 'calc(20px - ' + left + 'px)', marginBottom: '-30px' }}>
        <Alert onClose={handleClose} severity={props.type}>
            {props.message}
        </Alert>
    </Snackbar>)
}