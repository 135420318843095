import wave from '../../asset/home/vaporwave-seapunk.gif'
import daddy2 from '../../asset/home/daddy2.png'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';

export function Stake(props: any) {
    const extraSmall = useMediaQuery('(min-width: 500px)');
    const smHeight = useMediaQuery('(min-height: 680px)');
    const linkStyle = {
        textDecoration: 'underline',
        cursor: 'pointer',
        width: '100%',
        textAlign: 'right',
        height: 'fit-content',
        '& :hover': {
            color: "#f700c0",
        },
    } as const
    return (

        <Grid container sx={{ width: '80%', margin: 'auto', marginTop: '20%' }} >
            <Grid item xl={8} lg={8} md={6} sm={12} xs={12} sx={{ color: 'white', fontSize: "25px", padding: { xl: '80px', lg: '60px', md: '0px 30px', sm: '20px', xs: '20px' } }}>
                <Box onClick={props.onClick} className='title' sx={{ textTransform: 'uppercase', fontSize: (extraSmall ? '36px' : '25px'), fontWeight: '900', transform: "scaleY(1.5)", marginBottom: '20px', marginTop: (smHeight ? '50px' : '0px') }}>What are we?</Box>
                <Box sx={{ fontSize: (extraSmall ? '25px' : '18px') }}>{'Pinacotheca is a community owned and community run NFT marketplace made exclusively on EVMOS.'}</Box>
                <a href='https://medium.com/@Pinacotheca' target="_blank"><Box style={linkStyle} className="hoverRed" >{'Learn more >> '} </Box></a>
                {/* <Box sx={{ fontSize: (extraSmall ? '16px' : '16px'), textAlign:'right' }}>{'Built by experienced developers & Metis fans.'}</Box> */}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={10} xs={10} sx={{ marginTop: { xl: '0px', lg: '60px', md:'0',sm:'0',xs:'0' }, marginLeft: { xl:'0',lg:'0',md:'0',sm: '8.125%', xs: '8.125%' } }}><img src={wave} onClick={props.onClick} style={{ width: '100%' }} /></Grid>
        </Grid>
    )
}

function Font(props: any) {
    return (<Box className="stake-text" sx={{ display: 'block', color: props.color, textTransform: 'uppercase', width: { xl: '90vw', lg: '90vw', md: '90vw', sm: '90vw', xs: '100vw' }, fontWeight: '900', transform: "scaleY(2)", marginTop: '2px', textAlign: "center", }}>
        Trade, Stake <a id="pinkG" style={{ color: props.color2 }}>{' &'}</a> Chill. Get <a style={{ color: props.color2 }} id="pinkG">{' Rewarded'}</a>.</Box>)

}

export function FontSet(props: any) {
    const data = [
        //     {
        //     color: "rgba(225,225,225,0.05)",
        //     color2: "rgba(247,0,192,0.05)",
        //     margin: '30px',
        //     zIndex: '0',
        // }, {
        //     color: "rgba(225,225,225,0.1)",
        //     color2: "rgba(247,0,192,0.1)",
        //     margin: '60px',
        //     zIndex: '2',
        // }, {
        //     color: "rgba(225,225,225,0.2)",
        //     color2: "rgba(247,0,192,0.2)",
        //     margin: '90px',
        //     zIndex: '0',
        // }, {
        //     color: "rgba(225,225,225,0.4)",
        //     color2: "rgba(247,0,192,0.4)",
        //     margin: '60px',
        //     zIndex: '2',
        // }, {
        //     color: "rgba(225,225,225,0.7)",
        //     color2: "rgba(247,0,192,0.7)",
        //     margin: '30px',
        //     zIndex: '0',
        // },
        {
            color: "rgba(225,225,225,1)",
            color2: "rgba(247,0,192, 1)",
            margin: '0px',
            zIndex: '2',
        },
        {
            color: "rgba(225,225,225,0.7)",
            color2: "rgba(247,0,192,0.7)",
            margin: '30px',
            zIndex: '0',
        }, {
            color: "rgba(225,225,225,0.4)",
            color2: "rgba(247,0,192,0.4)",
            margin: '60px',
            zIndex: '2',
        }, {
            color: "rgba(225,225,225,0.2)",
            color2: "rgba(247,0,192,0.2)",
            margin: '90px',
            zIndex: '0',
        }, {
            color: "rgba(225,225,225,0.1)",
            color2: "rgba(247,0,192,0.1)",
            margin: '60px',
            zIndex: '2',
        }, {
            color: "rgba(225,225,225,0.05)",
            color2: "rgba(247,0,192,0.05)",
            margin: '30px',
            zIndex: '0',
        },
    ]

    return (<div style={{ position: 'absolute', color: 'white', top: '0', left: '5%' }} onClick={props.onClick}>
        <Box>{data.map((data) => (<Font color={data.color} color2={data.color2} />))}</Box>
    </div>)
}