import { useBalance, useContractWrite, useToken } from "wagmi"
import usdc from '../../asset/public/usdc.png'
import pina from '../../asset/public/plogo.png'
import { contract, getUsdcToken } from "../../config/contracts"
import { useContractRead } from 'wagmi'
import { getGenesisSaleAddress, getGenesisSaleAbi } from '../../config/contracts'
import { useEffect, useState } from "react"
import { utils, ethers } from "ethers"
import Box from '@mui/material/Box'

export default function PersonalInfo(props: any) {
    const [txnLoading, setTxnLoading] = useState(false)


    const [{ data, error, loading }, read] = useContractRead(
        {
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        },
        'investorInfoMap',
        { args: [props.address] }
    )

    const [{ data: claimData, error: claimError, loading: claimLoading }, claimRead] = useContractRead(
        {
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        },
        'purchasedAmount',
        { args: [props.address] }
    )

    const [{ data: claimEnabledData, error: claimEnabledError, loading: claimEnabledLoading }, claimEnabledRead] = useContractRead(
        {
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        },
        'claimEnabled'
    )

    const [{ data: claimWriteData, error: claimWriteError, loading: claimWriteLoading }, claimWrite] =
        useContractWrite({
            addressOrName: getGenesisSaleAddress(props.chainId),
            contractInterface: getGenesisSaleAbi(),
        }, 'claim')

    const wrap = () => {
        claimWrite()
        setTxnLoading(true)

    }
    if (claimWriteData && txnLoading) {
        claimWriteData.wait().then(() => {
            setTxnLoading(false)
            read()
            claimRead()
            claimEnabledRead()
        })
    }
    useEffect(() => {
        // Update the document title using the browser API
        if (props.address && props.chainId) {
            read()
            claimRead()
            claimEnabledRead()
        }
    }, [props.address]);

    useEffect(() => {
        // Update the document title using the browser API
        if (props.address && props.chainId) {
            read()
            claimRead()
            claimEnabledRead()
        }
    }, [props.update]);
    const contentStyle = {
        color: 'white',
        margin: 'auto',
        padding: '25px',
        marginBottom: '10px',
        borderRadius: '10px',
    }
    const style = {
        height: '20px',
        padding: '10px',
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '7px'
    } as const

    const getClaimablePina = (hasClaimed: boolean, claimData: number) => {
        if (hasClaimed) {
            return '0'
        } else {
            return claimData
        }
    }

    const getText = (hasClaimed: boolean, claimEnabled: any, claimData: number) => {
        if (hasClaimed) {
            return 'Already Claimed!'
        }
        if (claimEnabled && claimData) {
            return 'Claim ' + claimData.toFixed(2) + ' PINA'
        }
        if (claimEnabled && claimData === 0) {
            return 'Nothing to claim!'
        }
        if (!claimEnabled) {
            return <a style={{ cursor: 'not-allowed' }}>Claiming Disabled</a>
        }
        return ''
    }
    return (<Box sx={{ width: { xl: '100%', lg: '100%', md: '625px', sm: '625px', xs: '100%' }, margin: 'auto' }}>
        <div className='nav-title'>Your Participation Details</div>

        <div className="glassBackground" style={contentStyle}>
            <div style={{ fontSize: "14px", color: 'rgb(173, 175, 180)' }}>Pina Claimable</div>
            <div style={{ fontSize: "20px" }}>
                {(claimData && data) ? Number(getClaimablePina(data[2], formatFromBaseUnit(claimData, 18))).toFixed(2) : '0'}
                <img src={pina} style={{ width: '12px', marginBottom: '-1px', marginLeft: '10px' }} />
                <a style={{ fontSize: '14px' }}> PINA </a>
            </div>
            <div style={{ height: '12px', width: '100%' }} />

            <div style={{ fontSize: "14px", color: 'rgb(173, 175, 180)' }}>WETH Deposited</div>
            <div style={{ fontSize: "20px" }}>
                {data ? Number(ethers.utils.formatUnits(data[0], 18)).toFixed(2) : '0'}
                <img src={usdc} style={{ width: '12px', marginBottom: '-1px', borderRadius: '50%', marginLeft: '10px' }} />
                <a style={{ fontSize: '14px' }}> USDC </a>
            </div>
            <div style={{ height: '15px', width: '100%' }} />
            <div className="flowing-background" style={style}>
                <div style={{ marginTop: '-2px', fontWeight: '500' }} onClick={() => claimEnabledData && wrap()}>
                    {(claimEnabledData !== undefined && data && claimData)? getText(data[2], claimEnabledData, formatFromBaseUnit(claimData, 18)):<a className="not-allowed">Connect Wallet</a>}
                </div>
            </div>
        </div>
    </Box>)
}


const formatFromBaseUnit = (amount: any, decimals: any) => Number(ethers.utils.formatUnits(ethers.BigNumber.from(amount), decimals))
