import React, { useEffect, useRef, useState } from 'react'
import { Parallax, ParallaxLayer, IParallax } from '@react-spring/parallax'

import background from '../asset/home/background.jpg'
import daddy from '../asset/home/daddy.png'
import wave from '../asset/home/wave.png'

import Title from './homepage/title';
import Intro from './homepage/Intro';
import { Box } from '@mui/system';
import { FontSet, Stake } from './homepage/Stake';
import Roadmap from './homepage/Roadmap';
import useMediaQuery from '@mui/material/useMediaQuery';

import TV from './homepage/tv';

const url = (name: string, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`


export default function Home() {
  const parallax = useRef<IParallax>(null!)
  const [tvOn, turnOffTV] = useState(true)
  const small = useMediaQuery('(min-width: 1200px)');
  const extraSmall = useMediaQuery('(min-width: 500px)');
  return (
    <div className="content" style={{ width: '100%', height: '100%' }}>
      <Parallax ref={parallax} pages={5}>
        <ParallaxLayer
          offset={0}
          speed={0}
          factor={5}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />

        {/* page 0 */}
        <ParallaxLayer offset={0} speed={0.1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '100' }} >
          <TV display={tvOn} onClick={() => parallax.current.scrollTo(1)} />
        </ParallaxLayer>

        {/* page 1 */}
        <ParallaxLayer offset={1} speed={1} style={{ opacity: 1 }} >
          <img src={background} style={{ display: 'block', marginLeft: "30%", marginTop: '0%', height: '80%', width: '20%', objectFit: "cover" }} />
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={2} style={{ opacity: 1 }}>
          <img src={background} style={{ display: 'block', marginLeft: (small ? 'calc( 30% + 450px)' : '60%'), marginTop: '10%', height: '80%', width: '15%', objectFit: "cover" }} />
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={1.5} style={{ opacity: 1 }} >
          <Box className="bigTitle" sx={{ display: 'block', fontWeight: '900', width: '100%', marginTop: (small ? '18vh' : '40vh'), textAlign: 'center' }} >pinaco<Box style={{ width: '15%', display: 'inline-block' }} />theca</Box>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0.4} style={{ opacity: 1 }} >
          <img src={daddy} style={{ display: 'block', height: '80%', width: '40%', margin: '20px auto 0 auto', objectFit: "scale-down" }} />
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 1 }} onClick={() => parallax.current.scrollTo(2)}>
          <div className='subTitle' style={{ display: 'block', fontWeight: '900', width: '100%', marginTop: (small ? '30%' : '55vh'), textAlign: 'center', cursor: 'default' }}>YOUR evmos- nft gallery</div>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0.1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '100', height: 'fit-content' }}>
          <Title />
        </ParallaxLayer>



        {/* page 2 */}
        <ParallaxLayer offset={2} speed={1} onClick={() => parallax.current.scrollTo(3)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FontSet />
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={0.1} onClick={() => parallax.current.scrollTo(3)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Stake onClick={() => parallax.current.scrollTo(3)} />
        </ParallaxLayer>



        {/* page 3 */}
        <ParallaxLayer offset={3} speed={0.1} onClick={() => parallax.current.scrollTo(4)}
          style={{ display: small?'flex':'none', alignItems: 'center', justifyContent: 'center' }}>
          <Box className="title" sx={{ position: 'absolute', top: extraSmall?{ xl: '100px', lg: '50px', md: '50px', sm: '50px', xs: '20px' }:'0px', textTransform: 'uppercase', fontSize: '36px', fontWeight: '900', transform: "scaleY(1.5)" }}>RoadMap</Box>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={0.1} onClick={() => parallax.current.scrollTo(4)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80%', marginLeft: '10%' }}>
          <Roadmap />
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={1} style={{ opacity: 1 }} onClick={() => parallax.current.scrollTo(4)}>
          <img src={wave} className="wave" style={{ display: 'block', width: '100%', position: 'absolute', bottom: '0' }} />
        </ParallaxLayer>

        {/* page 4 */}
        <ParallaxLayer offset={4} speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Intro />
        </ParallaxLayer>

      </Parallax>
    </div>
  )
}
