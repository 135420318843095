import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Roadmap(props: any) {
    const matches = useMediaQuery('(min-width:1200px)');
    return (<Box>
        <Grid container sx={{ width: '100%', alignItems: 'center', display: 'flex', marginTop: { xl: '-200px', lg: '-200px', md: '200px' } }} spacing={{ xl: '20px', lg: '20px', md: '20px', sm: '20px', xs: '5px' }}>
        {!matches && (<Grid className="title" item xl={12} lg={12} md={12} sm={12} xs={12}sx={{ textTransform: 'uppercase', fontSize: (matches ? '36px' : '26px'), fontWeight: '900', transform: "scaleY(1.5)" }}>RoadMap</Grid>)}
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><Block title="Phase 1: Branding, Partnerships, Community, and Token Generation Event">
                - Refine our brand <br />
                - Build partnerships <br />
                - Build community <br />
                - Launch our Public Sale <br />
            </Block></Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><Block title="Phase 2: Grand Opening ">
                - Launch full-featured NFT marketplace<br />
                - Launch & airdrop community NFT collection <br />
                - Release roadmap v2<br />
            </Block></Grid>
        </Grid>
    </Box>)
}

function Block(props: any) {
    const matches = useMediaQuery('(min-width:500px)');
    const gmStyle = {
        height: { xl: '400px', lg: '400px', md: 'fit-content', sm: 'fit-content', xs: 'fit-content' },
        width: '100%',
        background: 'rgba( 255, 255, 255, 0.1)',
        boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
        backdropFilter: 'blur( 3px )',
        '-webkit-backdrop-filter': 'blur( 3px )',
        'borderRadius': '10px',
        border: '1px solid rgba( 255, 255, 255, 0.18 )',
    }

    return (<Box sx={gmStyle}>
        <Box sx={{ padding: { xl: '50px 30px', lg: '50px 30px', md: '50px 30px', sm: '50px 30px', xs: '20px' } }}>
            <Box className="roadmapTitle" sx={{ color: 'white', fontSize: { xl: '20px', lg: '20px', md: '20px', sm: '20px', xs: '16px' }, fontWeight: '700', transform: 'scaleY(1.2)' }}>{props.title}</Box>
            <Box sx={{ color: 'white', fontSize: (matches ? '16px' : '14px'), fontWeight:'600', marginTop: { xl: '40px', lg: '40px', md: '40px', sm: '40px', xs: '10px' } }}>{props.children}</Box>
        </Box>
    </Box>)
}