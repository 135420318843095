export type Chain = {
    id: number
    name: string
    nativeCurrency?: {
      decimals: 18
      name: string
      symbol: string
    }
    rpcUrls: string[]
    blockExplorers?: { name: string; url: string }[]
    testnet?: boolean
  }

export const Metis: Chain = {
    id: 1088,
    name:'Metis',
    nativeCurrency: {
        decimals: 18,
        name: 'METIS',
        symbol: 'METIS'
    },
    rpcUrls: ['https://andromeda.metis.io/?owner=1088'],
    testnet: false
}

export const Rinkeby: Chain = {
  id: 4,
  name:'Rinkeby',
  nativeCurrency: {
      decimals: 18,
      name: 'ETH',
      symbol: 'ETH'
  },
  rpcUrls: ['https://eth-rinkeby.alchemyapi.io/v2/MGgly6Tk14aP3pPMc4-h8YOQViA518J4'],
  testnet: true
}