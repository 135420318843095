import { Box } from "@mui/material"
import Typo from "../../component/typo"
import Grid from '@mui/material/Grid'
// import logo from "../asset/icon/nucode-logo.png"

export default function Navbar(props: any) {

    const navList = [{
    //     name: "Telegram",
    //     link: "https://twitter.com/PinacothecaNFT",
    // }, {
        name: "Discord",
        link: "https://discord.com/invite/pinacotheca",
    }, {
        name: "Twitter",
        link: "https://twitter.com/PinacothecaNFT",
    }, {
        name: "Medium",
        link: "https://medium.com/@Pinacotheca",
    }, {
        name: "Docs",
        link: "https://medium.com/@Pinacotheca",
    }]

    const navStyle = {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto',
        width: '100%',
        textTransform: 'uppercase',
    } as const

    return (
        <Grid container sx={{ width: '100%',height: 'fit-content' }}>
            <Grid item xl={6} lg={4} md={12} sm={12} xs={12} sx={{ width: 'fit-content', }}>
                {/* <img src={logo} style={{ width: '164px', height: '88px' }} /> */}
                {/* <Typo xs style={{ display: 'block', width: '100%' }}>Pinacotheca</Typo> */}
            </Grid>
            <Grid item xl={6} lg={8} md={12} sm={12} xs={12} sx={{ width: '100%', position: "relative", textAlign:'center' }} >
                <div style={navStyle}>
                    {navList.map((nav) => (
                        <a href={nav.link} target="_blank" className="nav-link">
                            <div className="nav">{nav.name}</div>
                        </a>
                    ))}
                </div>
            </Grid>
        </Grid>)
}