import {
  WALLET_CONNECTED,
} from "./actionTypes"

const initialState = {
  walletConnected: false,
}

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_CONNECTED:
      state = {
        ...state,
        connected: action.payload.walletConnected,
      }
      break
    default:
      state = { ...state }
  }
  return state
}

export default wallet
